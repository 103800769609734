import {currencies, marketSegmentNames, priceRegionNames} from '../../../models/utils/common';

export function getPriceRegionList() {
    let options = Object.keys(priceRegionNames)
    let priceRegionList = options.map(option => {
        return {
            label: `${priceRegionNames[option]}`,
            value: `${option}`
        };
    });
    return priceRegionList;
}

export function getCurrencies() {
    return Object.keys(currencies).map(option => {
        return {
            label: `${currencies[option]}`,
            value: `${option}`
        };
    });
}

export function getPriceRegion(priceRegion: string): string {
    return priceRegionNames[String(priceRegion)];
}

export function getMarketSegments() {
    let options = Object.keys(marketSegmentNames)
    let priceRegionList = options.map(option => {
        return {
            label: `${marketSegmentNames[option]}`,
            value: `${option}`
        };
    });
    return priceRegionList;
}

export function getPriceLevelList() {
    const priceLevels: {[code: string]: string} = {
        '01': 'Level 01',
        '02': 'Level 02',
        '03': 'Level 03',
        '04': 'Level 04',
        '12': 'Level 12',
        '13': 'Level 13',
        '14': 'Level 14',
        'T1': 'Level T1',
        'T2': 'Level T2',
        'T3': 'Level T3',
        'T4': 'Level T4',
        'T6': 'Level T6',
        'T7': 'Level T7',
        'TB': 'Level TB',
        'TC': 'Level TC',
        'TD': 'Level TD',
        'TE': 'Level TE',
        'TF': 'Level TF',
        'TG': 'Level TG'
    };

    let options = Object.keys(priceLevels)
    let priceLevelList = options.map(option => {
        return {
            label: `${priceLevels[option]}`,
            value: `${option}`
        };
    });
    return priceLevelList;
}
